<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getAccountingGLApi} from '@/api/accounting/query'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { financial } from '@/api/misc'

/**
 * User list component
 */
export default {
  page: {
    title: "General Ledger List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Financial Statement",
      items: [
        {
          text: "Accounting",
          href: "/",
        },
        {
          text: "Financial Statement",
          active: true,
        },
      ],
      currentPage: 1,
      start_date : '',
      end_date : '',
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
  },
  computed: {
    
  },

  created() {
    
  },
  mounted() {
  
  },
  methods: {


    f(v, d=2) {
      return financial(v, d)
    },

    queryList() {

 
      let data = {
        start_date : this.start_date,
        end_date   : this.end_date
      }
      getAccountingGLApi().financial_statement_query(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          res.data.map(e => this.listingData.push(e))
        }
      })
    },

    get_fs_total(item) {
      if (item.line_type == 'S' || item.line_type == 'D') {
        return financial(item.total, 2).toLocaleString()
      } else {
        return ''
      }
    }


  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-2">
                <label>Start Date </label>
                <flat-pickr
                  v-model="start_date"
                  placeholder="Select a date"
                  class="form-control"
                 
                  ></flat-pickr>
              </div>

              <div class="col-md-2">
                <label>End Date </label>
                <flat-pickr
                  v-model="end_date"
                  placeholder="Select a date"
                  class="form-control"
                 
                  ></flat-pickr>
              </div>
              <div class="col-md-4">
              </div>
              <div class="col-md-4 gap-2 mb-3 gap-2 mt-4">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                <b-button variant="primary" @click="queryList">Query</b-button>
                <b-button variant="primary" @click="queryList">Export PDF</b-button>
                <b-button variant="primary" @click="queryList">Export Excel</b-button>
              
                </div>
              </div>
              
            </div>
            <!-- end row -->

            <div class="py-2">
                <div class="pt-3"></div>
                <hr class="my" />

                <div class="table-responsive">
                  <div class="row pt-1"  v-for="(item, idx) in listingData" :key="'fs_item_'+idx">
                    <div class="row" v-if="item.line_type == 'H' || item.line_type == 'S' ||  item.line_type == 'D'">
                      <div class="row" :class="item.line_type == 'D'? 'pt-3' :''">
                        <div class="col-md-2" ><label :class="item.line_type == 'H'  ? ' font-size-16 font-weight-bold' : ' font-size-14'">{{ item.description }}</label></div>
                        <div class="col-md-8">{{ }}</div>
                        <div class="col-md-2 " ></div>
                      </div>
                    </div>
                    <div class="row" v-if="item.line_type == 'D'">
                      <div class="row"  v-for="(d, idx) in item.gls " :key="'fs_item_d'+idx" >
                        <div class="col-md-1"></div>
                        <div class="col-md-2">{{ d.account }}</div>
                        <div class="col-md-7">{{ d.description }}</div>
                        <div class="col-md-2 text-end" >{{f(d.total).toLocaleString()}}</div>
                      </div>
                    </div>
                    <div class="row" v-if="item.line_type == 'S' ||  item.line_type == 'D'">
                      <div class="row">
                        <div class="col-md-10"></div>
                        
                        <div class="col-md-2 border-top text-end font-weight-bold font-size-15">{{ get_fs_total(item) }}</div>
                      </div>
                    </div>

                  </div>
                </div>

            </div>


          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
